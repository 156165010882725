export default function FeaturedForm(el, {
    actions,
    events,
}) {
    const form = el.querySelector('form');
    const submit = form.querySelector('[type="submit"]');

    submit.removeAttribute('disabled');

    form.onsubmit = e => {
        e.preventDefault();

        submit.setAttribute('disabled', 'true');

        const body = new FormData(form);

        fetch('/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
            },
            body,
        })
            .then(res => res.json().then(json => ({
                status: res.status,
                ...json,
            })))
            .then(({
                status,
                message = '',
                errors = {},
            }) => {
                // Reset all field errros to empty
                Array.from(body.keys()).map(name => name.replace('[]', '')).forEach(name => {
                    events.emit(actions.showFieldError, { name, errors: [] });
                });
                submit.removeAttribute('disabled');

                switch (status) {
                case 500:
                    window.alert(message); // eslint-disable-line no-alert

                    break;
                case 400:
                    Object.entries(errors).forEach(([name, errs]) => {
                        events.emit(actions.showFieldError, { name, errors: errs });
                    });

                    break;
                case 200:
                default:
                    form.onsubmit = null;
                    form.parentElement.innerHTML = '<p>Thank you for your submission.<br />A Sonos representative will contact you as soon as possible.</p>';
                }
            });
    };
}
