export default async function installersListing(el, {
    mapHandle,
    pinHandle,
    activeClass = 'is-active',
    installers = [],
}) {
    const form = el.querySelector('form');
    const options = form.querySelectorAll('[type="radio"]');
    const menuToggle = el.querySelector('#menu-toggle');

    document.onkeyup = ({ code }) => {
        if (code === 'Escape') {
            menuToggle.checked = false;
        }
    };
    options.forEach(o => {
        o.onchange = () => { form.submit(); };
    });

    /* eslint-disable no-undef */
    const { LatLngBounds } = await google.maps.importLibrary('core');
    const { Map, InfoWindow } = await google.maps.importLibrary('maps');
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');
    const { spherical } = await google.maps.importLibrary('geometry');
    /* eslint-enable no-undef */

    const mapCont = el.querySelector(mapHandle);
    const pin = el.querySelector(pinHandle);
    const installersListings = el.querySelectorAll('nav > ul > li');
    const [lats, lngs] = installers
        .reduce((acc, { lat, lng }) => [acc[0] + lat, acc[1] + lng], [0, 0]);
    const center = installers.length
        ? { lat: lats / installers.length, lng: lngs / installers.length }
        : { lat: -25.67395577550897, lng: 134.61042850000004 };
    const bounds = new LatLngBounds(center);
    const map = new Map(mapCont, {
        zoom: installers.length === 1 ? 15 : 5,
        center,
        mapId: 'Sonos AV Installers',
        disableDefaultUI: true,
        zoomControl: true,
    });
    const markers = installers.map(({ title, url, lat, lng }) => {
        const pinContent = pin.cloneNode(true);
        const position = { lat, lng };

        if (installers.length > 1) {
            bounds.extend(position);
        }

        return [
            pinContent,
            new InfoWindow({
                content: `<a href="${url}">${title}</a>`,
            }),
            new AdvancedMarkerElement({
                map,
                position,
                title,
                content: pinContent,
            }),
        ];
    });

    installersListings.forEach((l, i) => {
        const dist = spherical.computeDistanceBetween(installers[i], center) / 1000;

        l.querySelector('article > nav > em').textContent = `${dist.toFixed(1)} km`;
        l.onmouseenter = () => {
            const [p, w, m] = markers[i];

            p.style.transform = 'scale(1.5)';
            w.open({ anchor: m });
            l.classList.add(activeClass);
        };
        l.onmouseleave = () => {
            const [p, w] = markers[i];

            p.style.transform = 'scale(1)';
            w.close();
            l.classList.remove(activeClass);
        };
    });
    if (installers.length > 1) {
        map.fitBounds(bounds);
    }
    markers.forEach(([p, w, m], i) => {
        m.addListener('click', () => {
            markers.forEach(([pp, ww]) => {
                pp.style.transform = 'scale(1)';
                ww.close();
            });
            p.style.transform = 'scale(1.5)';
            w.open({ anchor: m });
            installersListings.forEach((l, ii) => {
                if (l.classList.toggle(activeClass, i === ii)) {
                    l.scrollIntoView({ behavior: 'smooth' });
                }
            });
        });
    });
}
