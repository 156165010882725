export default async function InstallerDetails(el, {
    pinHandle,
    lat,
    lng,
}) {
    /* eslint-disable no-undef */
    const { Map } = await google.maps.importLibrary('maps');
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');
    /* eslint-enable no-undef */

    const mapConts = el.querySelectorAll('aside');
    const pin = el.querySelector(pinHandle);
    const maps = [...mapConts].map(c => new Map(c, {
        zoom: 16,
        center: { lat, lng },
        mapId: 'Sonos AV Installer',
        disableDefaultUI: true,
    }));

    maps.forEach(map => {
        new AdvancedMarkerElement({ // eslint-disable-line no-new
            map,
            position: { lat, lng },
            content: pin.cloneNode(true),
        });
    });
}
