export default function Header(el, {
    overlay = false,
    fixedClass = 'is-fixed',
    peekedClass = 'is-peeked',
}) {
    const close = el.querySelector('button');

    let yp = 0; // Track previous scroll position

    function handleScroll() {
        const y = window.pageYOffset || document.documentElement.scrollTop;
        const { offsetHeight: h } = el;
        const offset = overlay ? 0 : h;

        if (!el.classList.toggle(peekedClass, y < yp) || y === 0) {
            document.body.style.paddingTop = el.classList.toggle(fixedClass, y > h) ? `${offset}px` : '0';
        }

        yp = Math.max(y, 0);
    }

    window.addEventListener('scroll', handleScroll, false);
    if (close) {
        close.onclick = () => { close.parentElement.remove(); };
    }
}
