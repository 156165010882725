// Framework
import pop from './components';

// Components
import Form from './components/form';
import FormField from './components/form-field';
import Header from './components/header';
import InstallerDetails from './components/installer-details';
import InstallersListing from './components/installers-listing';

// Define map of component handles to component classes
const classMap = {
    form: Form,
    'form-field': FormField,
    header: Header,
    'installer-details': InstallerDetails,
    'installers-listing': InstallersListing,
};

// Define all actions/commands that components pub/sub
const actions = {
    // Action events
    lockScroll: 'lock-scroll',
    unlockScroll: 'unlock-scroll',
    openModal: 'open-modal',
    closeModal: 'close-modal',
    loadModal: 'load-modal',
    showFieldError: 'show-field-error',
};

// Event handler functions
function handleDOMConentLoaded() {
    function cb() {
        document.querySelectorAll('a[href^="#"]').forEach(l => {
            l.addEventListener('click', e => {
                e.preventDefault();

                document.querySelector(l.getAttribute('href')).scrollIntoView({
                    behavior: 'smooth',
                });
            });
        });
    }

    pop({ classMap, actions, cb });
}

// Add event listeners
document.addEventListener('DOMContentLoaded', handleDOMConentLoaded);
