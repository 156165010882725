export default function ScheduleForm(el, {
    name,
    errorClass,
    actions,
    events,
}) {
    const error = el.querySelector('p');

    events.on(actions.showFieldError, ({ detail }) => {
        if (detail.name === name) {
            el.classList.toggle(errorClass, detail.errors.length > 0);
            error.textContent = detail.errors.join(', ');
        }
    });
}
